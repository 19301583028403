@font-face {
  font-family: "lucidacalligraphy";
  src: url("../../fonts/lucida\ calligraphy\ italic.ttf") format("truetype");
  /* Additional font styles like font-weight, font-style can be specified here */
}

nav {
  padding: 5px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  font-size: larger;
}
.menu-icon {
  display: none;
  cursor: pointer;
}

.menu-icon .times {
  transform: rotate(360deg);
  transition: all 0.2s ease-in-out;
  color: #000;
}

.menu-icon .bars {
  transform: rotate(180deg);
  transition: all 0.2s ease-in-out;
  color: #000;

}
.logo {
  position: relative;
  width: 100%;
  display: flex;
}
.logo_img {
  width: 90px;
  height: 70px;
  color: #2680BC;
}
.logo_img1 {
  width: 90px;
  height: 70px;
  color: #2680BC;
  position: absolute;
  top: 10px;
  left: 110px;

}
.logo_sub {
  position: absolute;
  bottom: 7px;
  font-family: "lucidacalligraphy";
  /* color: #ff6247; */
  color: #20d3d3;
  font-size: 1rem;
  font-weight: 600;
  left: 115px;
}
.menu-list {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 36px;
  background-color: #fff;
}
.menu-list li {
  text-decoration: none;
  font-weight: 500;
  /* color: #00000077; */
  padding: 5px 10px;
  margin-left: 20px;
  background:#2D3088;
  border-radius: 20px;
  
}
.menuList_hover:hover {
  color: #37d3d3;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 1350px) {
  .logo_sub{
    top: 60%;
  }

}

@media screen and (max-width: 992px) {
  .menu-list li {
    font-size: 14px;
    margin-left: 10px;
  }

}

@media screen and (max-width: 596px) {
  nav {
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 99;
  }
  .menu-icon {
    display: block;
    background-color: black;
    opacity: 50%;
    border-radius: 20%;
  }
  .menu-list {
    flex-direction: column;
    width: 100%;
    position: absolute;
    background:#fff;
    left: 0;
    top: 115px;
    transition: all 0.7s ease-in-out;
  }
  .menu-list li {
    padding: 20px;
    width: 100%;
    text-align: center;
    
    color: #fff;
  }
  .menu-list li:last-child {
    margin-bottom: 0px;
  }

  .menu-list.close {
    left: -110%;
    transition: all 0.5s ease-in-out;
    background-color: black;
    opacity: 50%;
    color: #000;
  }
}
