/* .youtube-video-container {
    display: flex;
    justify-content: center;
  }
  
  .youtube-video-container iframe {
    max-width: 100%;
    border: none;
   
  } */
  /* .youtube-video-container {
    display: flex;
    justify-content: center;
    margin: 120px;
  }
  
  .youtube-video-container iframe {
    width: 100%;
    max-width: 760px;
    height: 315px;
    border: none;
  }
  
  @media (max-width: 768px) {
    .youtube-video-container iframe {
      height: 240px;
      margin: 0;
      width: 100vw;
    }
  }
  
  @media (max-width: 480px) {
    .youtube-video-container iframe {
      height: 180px;
      margin: 0;
      width: 100vw;
    }
  }
   */

   /* index.css */
   /* .heading {
    color:black;
    position: absolute;
    top: 10%;
  
  }
.youtube-video-container {
    width: 100%;
    max-width: 760px;
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .youtube-video-container iframe {
    width: 100%;
    height: 315px;
  }
  .swiper-container {
    width: 100%;
    max-width: 600px; 
    margin: 0 auto;
  }
  
  .swiper-image {
    width: 100%;
    height: 400px; /
    object-fit: cover; 
  } */
  
  /* Media queries for responsiveness */
  /* @media (max-width: 768px) {
    .youtube-video-container iframe {
      height: 250px;
    }
  }
  
  @media (max-width: 480px) {
    .youtube-video-container iframe {
      height: 200px;
    }
  }

  
  .typewriter-text {
    font-size: 2em;
    animation: typing 1.5s steps(40, end) 1s 1 normal both,
               blinkTextCursor 500ms steps(40, end) infinite normal;
  }
  
  @keyframes typing {
    from { width: 0; }
    to { width: 100%; }
  }
  
  @keyframes blinkTextCursor {
    from { border-right-color: rgba(255, 255, 255, 0.75); }
    to { border-right-color: transparent; }
  }
  
  .slide-in {
    animation: slideIn 2s ease-out;
  }
  
  @keyframes slideIn {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  

  
  .lets-talk:hover, .explore-us:hover {
    background-color: #0056b3;
  }
  
  .arrow-icon {
    margin-left: 10px;
  }
   */



   /* Events.css */
.events-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100vh; /* Full viewport height */
    /* background: linear-gradient(50deg,#F80594,#15BEF5); */

  }
  .comingsoon{
    position: absolute;
    top: 22%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70%;
    width: 60%;
  }
  
  .animated-text {
    font-size: 2.5rem;
    color:#FF8C78; /* Light blue color */
    animation: bounce 2s infinite;
  }

  
 
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  .text {
    text-align: center;
    padding-inline:120px;

  }

  @media screen and (max-width: 768px){
    .text{
      padding-inline: 40px;
    }
    .comingsoon{
      position: absolute;
      top: 22%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70%;
      width: 80%;
    }
  }
