/* .background-screen{
  background-color: beige;
} */

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}
.heading {
  color:black;
  position: absolute;
  top: 30%;
  z-index: 2;

}
.heading h5 {
  font-weight: 400;
  font-size: 20px;
}
.heading h1 {
  font-size: 40px;
  margin: 4px 0px;
  width: 100%;
}
.heading h3 {
  text-align: right;
  font-size: 22px;
  font-weight: 400;
  margin: 4px 0px 12px 0px;
}


.typewriter-text {
  overflow: hidden; /* Ensures the text is not visible outside the containing element */
  white-space: nowrap; /* Prevents text from wrapping */
  margin: 0 auto; /* Center the text horizontally */
  background:  #1bffff;
  /* background: linear-gradient(40deg,#2D3088,#243970); */
  /* background: #20D3D3; */
  /* background-color: #fff; */

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Hide the original text color */
  background-clip: text;
  text-fill-color: transparent; /* Hide the original text color */
  animation: typing 2.5s steps(50, end) 1 ;
}
.highlight {
  /* background: #2603ef; */
  /* background-color: #1780c6; */
  background-color: #2D3088;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Hide the original text color */
  background-clip: text;
  text-fill-color: transparent; /* Hide the original text color */
}

.typewriter-text1 {
  overflow: hidden; /* Ensures the text is not visible outside the containing element */
  white-space: nowrap; /* Prevents text from wrapping */
  margin: 0 auto; /* Center the text horizontally */
  /* background:  #1bffff; */
  background: linear-gradient(40deg,#DE43B0, #43AAE9);
  /* background-color: #fff; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Hide the original text color */
  background-clip: text;
  text-fill-color: transparent; /* Hide the original text color */
  animation: typing 2.5s steps(50, end) 1 ;
}

/* Animation and keyframes for the typewriter effect */
@keyframes typing {
  0% {
    width: 0;
    /* border-right: 4px solid #30bebe; */
  }
  50% {
    width: 50%;
    /* border-right: 4px solid #30bebea4; */
  }
  80% {
    width: 80%;
    /* border-right: 4px solid #30bebe4d; */
  }
  100% {
    width: 100%;
    border-right: transparent;
  }
}

.services_cards{
  /* background-color: #222222; */
  width: 90%;
  height: 32%;
  margin-top: 25%;
  display: flex;
  gap: 2%;
  position: absolute;


}

.our_service_card1{
  width: 31%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;
  padding: 40px 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background: #fff;
}

.icon1 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  /* background-color:#2680BC; */
}

.card1{
  height: 100%;
  width: 100%;
  background-color: #ffffff56;
  gap: 2%;
  opacity: 1;

  display: flex;
}
.card_heading1{
  font-size: 1rem;
  color: 
#064f81;
}
.slide-in{
  position: absolute;
  gap: 10%;
  display: grid;
}
.home-buttons {
  display: flex;
  justify-content: center;
  gap: 32px;
  position: absolute;
  bottom: -100%;
}
.home-buttons button {
  padding: 12px 24px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: background-color 1.2s ease;
  font-size: 16px;
}

.lets-talk {
  background-color: #fff;
  color: #2e3192;
}
.lets-talk:hover {
  background-color: #2e3192;
  color: #fff;
  transition: all 0.2s ease-in-out;
}
.explore-us {
  background-color: #2e3192;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  transition: transform 0.1s ease-in-out;
}
.arrow-icon {
  /* rotate: -90deg; */
  padding-left: 3px;
  margin-left: 6px;
}
.explore-us:hover .arrow-icon {
  transform: translateY(5px); /* Adjust the distance as needed */
}

.why-choose-us {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;
  /* background-image: linear-gradient(to right,#E6E6FA, #F8F8FF,#FFFFFF); */
  background-color: #fff;
}

.why-choose-us-head {
  font-size: 2.5rem;
  width: 90%;
  max-width: 600px;
  color: #2680BC;
}
.why-choose-us-description {
  font-size: 1rem;
  width: 90%;
  max-width: 600px;
  margin-bottom: 36px;
  color: #777;
}

.choose-us-card {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.heading_text {
  color: #222222;
  font-weight: 600;
  font-size: 1.3rem;
  margin-bottom: 10px;
}
.description_text {
  color: #777;
  width: 90%;
  font-size: 1rem;
  font-weight: 400;
}
/* .chrono-cont {
  height: auto;
  border: 1px solid red;
} */

/* .my-card-title:hover {
 background-color: #1bffff;
} */
@media screen and (max-width: 1350px) {
  
.services_cards{
  /* background-color: #222222; */
  width: 90%;
  height: 32%;
  margin-top: 35%;
  display: flex;
  gap: 2%;
  position: absolute;


}

}
@media screen and (max-width: 992px) {
  *{
   
    box-sizing: border-box;
  }
  .container {
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
  }
  .content {
    width: 100%;
  }
  .services_cards{
    /* background-color: #222222; */
    width: 100%;
    height: 5px;
    margin-top: 50px;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2%;
    position: absolute;
  
  
  
  }
  

.our_service_card1 {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    padding:  20px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background: #fff;
    margin-bottom: 15px;
    height: 170px;
}
  .card1{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    height: 2%;
    width: 100%;
    position: absolute;

  }
 
  .why-choose-us-description {
    font-size: 18px;
  }

  .heading {
    width: 90vw;
  }
  .why-choose-us-description {
    font-size: 18px;
  }
  .heading h5 {
    font-weight: 200;
    font-size: 20px;
    width: 100%;
  }
  .heading h1 {
    font-size: 30px;
    font-weight: 300;
    margin: 4px 0px;
    width: 100%;
  }
  .heading h3 {
    text-align: right;
    font-size: 22px;
    font-weight: 400;
    margin: 4px 0px 12px 0px;
    width: 90%;
  }
  .typewriter-text {
    font-size: 1.2rem; /* Adjust font size for better visibility on smaller screens */
  }
}

@media screen and (max-width: 596px) {
  *{
    margin: 0;
    padding: 0%;
  }

  .heading h5 {
    font-size: 14px;
  }
  .heading h1 {
    font-size: 15px;
    font-weight: bold;
  }
  .heading h3 {
    font-size: 14px;
  }
  .home-buttons button {
    padding: 5px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    transition: background-color 1.2s ease;
    font-size: 12px;
  }
  .why-choose-us {
    padding: 16px;
    padding-top: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    text-align: center;
    /* background-image: linear-gradient(to right,#E6E6FA, #F8F8FF,#FFFFFF); */
    background-color: #fff;
  }

  .why-choose-us-description {
    font-size: 16px;
  }
  .description_text {
    color: #777;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
  }
  .fUSemk {
    font-size: 0.9rem !important;
    padding: 0px !important;
  }
  .services_cards{
    /* background-color: #222222; */
    display: grid;
    width: 100%;
    height: 5px;
    margin-top: 50px;
    grid-template-columns: 1fr 1fr ;
    gap: 2%;
    position: absolute;
  
  
  
  }
  

.our_service_card1 {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    padding:  10px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background: #fff;
    height: 100px;
}
  .card1{
    display: grid;
    grid-template-columns: 1fr 1fr  ;
    height: 2%;
    width: 100%;
    position: absolute;

  }
  .card_heading1{
    display: none;
  }
  .icon1 {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    /* background-color:#2680BC; */
    padding: 10px;
  }
 
}

@media screen and (max-width: 375px) {
  .fUSemk {
    font-size: 0.8rem !important;
  }
  .why-choose-us-head {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 425px) {
  .why-choose-us-head {
    font-size: 2rem;
  }
}
